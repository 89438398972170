import './footer.css'
import { FaFacebookF } from 'react-icons/fa'
import { BsTwitterX } from "react-icons/bs";
import { BsLinkedin } from 'react-icons/bs'  
import { FaGithub } from 'react-icons/fa'
import { FiInstagram } from 'react-icons/fi'


const Footer = () => {
    return (
        <footer>
            <ul className='permalinks'>
                <li><a href='/#'>Home</a></li>
                <li><a href='/#about'>About</a></li>
                <li><a href='/#experience'>Experience</a></li>
                <li><a href='/#portfolio'>Portfolio</a></li>
                <li><a href='/#involved'>Involved</a></li>
                <li><a href='/#misc'>Interests</a></li>
                <li><a href='/#contact'>Contact</a></li>
            </ul>

            <div className="footer__socials">
                <a href="https://www.facebook.com/davide.scarra.33/"><FaFacebookF /></a>
                <a href="https://twitter.com/davide_scarra"><BsTwitterX /></a>
                <a href="https://www.linkedin.com/in/davide-scarra/"><BsLinkedin/></a>
                <a href="https://github.com/LoScarro"><FaGithub/></a>
                <a href="https://www.instagram.com/lo_scarro/"><FiInstagram/></a>
            </div>
        </footer>
    )
}

export default Footer