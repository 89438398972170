import './misc.css'
import { Spotify } from 'react-spotify-embed'
import Duke from '../../assets/duke.jpg'

const Misc = () => {
    return (
        <section id='misc'>
            <h5>My Interests</h5>
            <h2>What I do in my free time</h2>
            <div className='container misc__container'>
                <Spotify className="misc__item-music" link="https://open.spotify.com/playlist/3QguxrFKeX0EKqhg32QwWD?si=16847b5caf5d4d9a" />
                <div className="misc__item-games">
                    <a href={new URL('/clips', window.location.href).toString()}><img className="misc__item-image" src="https://upload.wikimedia.org/wikipedia/en/1/14/Halo_Infinite.png" alt="Halo Infinite" /></a>
                    <a href="https://www.liesofp.com/en-us/"><img className="misc__item-image" src="https://upload.wikimedia.org/wikipedia/en/d/de/Lies_of_p_cover_art.jpg" alt="Lies of P" /></a>
                    <a href="https://diablo4.blizzard.com/en-us/"><img className="misc__item-image" src="https://upload.wikimedia.org/wikipedia/en/1/1c/Diablo_IV_cover_art.png" alt="Diablo IV" /></a>
                </div>
                {/* motorbike photo */}
                <div className="misc__item-motorbike">
                    <img className="misc__item-image" src={Duke} alt="My KTM Duke 125" />
                </div>
                    
            </div>
        </section>
    )
}

export default Misc
